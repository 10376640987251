import React, { useState, useEffect } from 'react';
import BackgroundVideo from "./BackgroundVideo";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import blancLogoWhite from './assets/BlancArchWhite.png';
import blancLogoBlack from './assets/BlancArchBlack.png';
import dalBlack from './assets/dalBlack.png'
import dalWhite from './assets/dalWhite.png'

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home'
import Services from './pages/Services';
import About from './pages/About';
import Order from './pages/Order';
import Contact from './pages/Contact';
import ContactForm from './pages/ContactForm';
import Footer from './Footer';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [logo, setLogo] = useState(blancLogoWhite);
  const [dal, setDal] = useState(dalWhite);
  

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 1080);
    if (scrollTop > 1080) {
      setLogo(blancLogoBlack);
      setDal(dalBlack)
    } else {
      setLogo(blancLogoWhite);
      setDal(dalWhite)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div>
        <div>
          <BackgroundVideo />
        </div>
        
        <nav className={`navbar navbar-expand-lg ${isScrolled ? 'scrolled' : ''} fixed-top`}>
          <img src={logo} alt="Logo" className="rounded float-start m-4" style={{ maxWidth: '300px' }}/>
          <div class="container-fluid">
            <div class="collapse navbar-collapse d-flex justify-content-center" id="navbarNav">
              <ul class="navbar-nav nav-underline justify-content-center">
                <li class="nav-item">
                  <Link className="nav-link" to="/">Anasayfa</Link>
                </li>
                <li class="nav-item">
                  <Link className="nav-link" to="/services">Hizmetlerimiz</Link>
                </li>
                <li class="nav-item">
                  <Link className="nav-link" to="/about">Hakkımızda</Link>
                </li>
                <li class="nav-item">
                  <Link className="nav-link" to="/order">Sipariş</Link>
                </li>
                <li class="nav-item">
                  <Link className="nav-link" to="/contact">İletişim</Link>
                </li>
              </ul>
            </div>
          </div>
          <img src={dal} alt="dal" className="rounded float-start mt-4" style={{ maxWidth: '300px' }}/>
        </nav>

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/order" element={<Order />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/form" element={<ContactForm />} />
          </Routes>

       

        {/*İletişim*/}


      </div>
    </Router>
  );
}

export default App;
