import React from 'react';
import './Contact.css'; // İsteğe bağlı CSS dosyası
import contactImage from '../assets/contact.png'; // İletişim resmi (bu yolu kendi resminizle değiştirin)

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-card">
      <img src={contactImage} alt="İletişim" className="contact-image" />

        <p className='fs-3'>
        Bize biraz bilgi verin, size en uygun çözümü sunalım. Telefonla, sohbet hattından, e-postayla veya başka bir yolla bağlantı kurun.
        </p>
        <br></br>
        <div className="contact-details">
          <h2>Email:</h2>
          <p className='fs-4'>suzgenabdulsamet@outlook.com</p>
          <h2>Telefon:</h2>
          <p className='fs-4'>+90 546 601 72 02</p>
        </div>
      </div>

    </div>
  );
};

export default Contact;
