// BackgroundVideo.js
import React from "react";
import "./BackgroundVideo.css"; // CSS dosyası için

const BackgroundVideo = () => {
  return (
    <div className="video-container">
      <video autoPlay loop muted className="background-video">
        <source src="/video.mp4" type="video/mp4" />
        Tarayıcınız bu videoyu desteklemiyor.
      </video>
      <div className="content">
        {/* Video üstü içerik */}
        <h1 >BlancArch</h1>
      </div>
    </div>
  );
};

export default BackgroundVideo;
