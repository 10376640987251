import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Burada form verilerini işleyin ve e-posta gönderin (örneğin, bir API çağrısı ile)
    console.log(formData);
    // Örnek: window.location.href = 'mailto:your-email@example.com?subject=Contact Form&body=' + JSON.stringify(formData);
  };

  return (
    <div className="contact-form">
      <h1>İletişim Formu</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <input
            type="text"
            name="firstName"
            placeholder="İsim"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="lastName"
            placeholder="Soyisim"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row">
          <input
            type="email"
            name="email"
            placeholder="E-posta"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Telefon Numarası"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <textarea
          type="text"
          name="address"
          placeholder="Adres"
          value={formData.address}
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          placeholder="Açıklama"
          value={formData.description}
          onChange={handleChange}
          required
        />
        <button type="submit" className="submit-button">Gönder</button>
      </form>
    </div>
  );
};

export default ContactForm;
