import React, { useState } from 'react';
import './ServicesCard.css';
import './Services.css';

function ServicesCardFirst({ image, title, content, popupContent, popupImage  }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="card-with-image d-flex align-items-center mt-3">

      <div className="card-content p-3" style={{ width: '50%' }}>
        <h3>{title}</h3>
        <p>{content}</p>
        <button onClick={handleButtonClick} className="modern-button">Detayları Gör</button>

        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <span className="close-btn" onClick={() => setShowPopup(false)}>&times;</span>
              <img src={popupImage} alt="Popup" className="popup-image" />
              <h2 className="popup-title">{popupContent.title}</h2>
              <p className="popup-description">{popupContent.description}</p>
              <button className="modern-button" onClick={() => window.location.href = popupContent.redirectTo}>
                Form Sayfasına Git
              </button>
            </div>
          </div>
        )}
      </div>
      <img src={image} alt={title} className="img-fluid" style={{ width: '50%' }} />
    </div>
  );
}

export default ServicesCardFirst;
