import React from 'react';
import './Order.css'; // CSS dosyası

const Order = () => {
  return (
    <div className="order-container">
      <h1>Sipariş</h1>
      <p>Bu bölüm yakında hizmetinize sunulacaktır. Lütfen bizi izlemeye devam edin!</p>
      <div className="coming-soon">
        <h2>Yakında Sizlerleyiz!</h2>
      </div>
    </div>
  );
};

export default Order;
